import {
    Async,
    CommandBarButton,
    DirectionalHint,
    FocusZone,
    FocusZoneDirection,
    FocusZoneTabbableElements,
    IButton,
    Icon,
    IconButton,
    MessageBarType,
    TextField,
    TooltipHost
} from "@fluentui/react";
import { observer } from "mobx-react";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import * as React from "react";
import { TAGNAME_CHAR_LIMIT, TAGNAME_ERROR_TIMEOUT, TEAM_PICKER_ICON_ID } from "sh-application/../StaffHubConstants";
import setGlobalMessages from "sh-application/actions/setGlobalMessages";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import {
    ScheduleGridUtils,
    setIsReorderTagsPanelOpen,
    setMemberPanelValues,
    toggleTagView,
    updateFilteredTags
} from "sh-application/components/schedules/lib";
import { StaffHubTourMarker } from "sh-application/components/tour/sh-tour/StaffHubTourMarker";
import { appViewState, setHoverColumnHidden } from "sh-application/store";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import DateUtils from "sh-application/utility/DateUtils";
import InstrumentationUtils from "sh-application/utility/InstrumentationUtils";
import KeyboardUtils from "sh-application/utility/KeyboardUtils";
import TagUtils from "sh-application/utility/TagUtils";
import showConfirmDialog from "sh-confirm-dialog/lib/components/ConfirmDialog";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { ITagEntity } from "sh-models";
import {
    ECSConfigKey,
    ECSConfigService,
    InstrumentationService,
    StaffHubErrorCodes,
    TagDataService
} from "sh-services";
import StringsStore from "sh-strings/store";
import { TagStore, setIsFocusedTag } from "sh-tag-store";

import { StaffHubHttpError } from "../../..";
import SpacerItem from "../../commandbar/SpacerItem";
import { PeopleCount } from "../../common/peopleCount/PeopleCount";
import { setMemberDialogOpen, setMemberOpenDialogTriggerButton } from "../../memberEditor/lib";
import schedulesViewStateStore from "../../schedules/lib/store/store";

import { getDeleteGroupMenuProps, getPeopleCountProps, getRenameGroupMenuProps } from "./TagHeader.mappers";
import { TagHeaderProps } from "./TagHeaderProps";
import setTagName from "./lib/mutators/setTagName";
import setTagNameError from "./lib/mutators/setTagNameError";
import { TagHeaderViewStateStore } from "./lib/store/schema/TagHeaderViewStateStoreSchema";
import createtagHeaderViewStateStore from "./lib/store/store";

const styles = require("./TagHeader.scss");
const classNames = require("classnames/bind");
const cssExports = require("styles/Exports.scss");

/**
 * Calculate the element id for a tag group header element.
 * This is used for Aria accessibility label references
 * @param tagId
 */
export function calculateTagHeaderElementId(tagId: string): string {
    return tagId ? `sh-th-${tagId}` : "sh-th-none";
}

/**
 * The header component for groups in Schedule page.
 */
@observer
export default class TagHeader extends React.Component<TagHeaderProps, {}> {
    private _strings: Map<string, string>;
    private _commonStrings: Map<string, string>;
    private _tagHeaderViewState: TagHeaderViewStateStore;
    private static _textFieldHasFocus: boolean = false; // this static member is shared by all tag headers and is used to determine visibility of the hover column
    private _isMouseOverTextField: boolean = false;
    private _addMember = React.createRef<IButton>();
    private _moreMenuButton = React.createRef<IButton>();
    private _async = new Async(this);

    constructor(props: TagHeaderProps) {
        super(props);
        /* Import string modules */
        this._strings = StringsStore().registeredStringModules.get("schedulePage").strings;
        this._commonStrings = StringsStore().registeredStringModules.get("common").strings;
        this._tagHeaderViewState = createtagHeaderViewStateStore(this.getTagId());

        // this._tagHeaderViewState is used to back the TextField component with an observable value for the tag group
        // name.
        // Here we initialize the viewstate with the current tag group name value from props.
        if (props.tag) {
            setTagName(this._tagHeaderViewState, props.tag.name);
        }
    }

    /**
     * Cleans up timer when component is unmounted.
     */
     componentWillUnmount() {
        // clear all intervals
        this._async.dispose();
    }

    /**
     * UNSAFE_componentWillReceiveProps will run any time the service updates that tag name.
     * This is to ensure there isn't a cached version of edits that could reset the tag name.
     * @param nextProps
     */
    UNSAFE_componentWillReceiveProps(nextProps: TagHeaderProps) {
        if (nextProps.tag) {
            setTagName(this._tagHeaderViewState, nextProps.tag.name);
        }
    }

    /**
     * Return the tag id
     */
    private getTagId(): string {
        return this.props.tag ? this.props.tag.id : this.props.otherGroupTagId;
    }

    /**
     * Return the display name for the tag
     */
    private getTagName(): string {
        return this._tagHeaderViewState.tagName || this.props.otherGroupTagName;
    }

    /**
     * Return the Aria accessibility label text for the tag header
     */
    private getTagHeaderAriaLabel(): string {
        const tagName: string = this.getTagName();
        return tagName ? tagName : this._strings.get("emptyTagGroupNameAria");
    }

    /**
     * Renders the group name and hours for the new schedule UI.
     */
    private renderFREUIForGroupNameAndHours() {
        const { editEnabled, tag } = this.props;
        const peopleCountProps = getPeopleCountProps(tag, this.props.groupData);

        return <>
            {this.renderCollapseButton()}
            {this.renderGroupNameFREUI()}
            {this.renderGroupHours()}
            {peopleCountProps ? <PeopleCount {...peopleCountProps} /> : null}
            {
                editEnabled ?
                <>
                    {SpacerItem.getComponent(true, undefined, true)}
                    {this.renderMoreIcon()}
                </> : null
            }
        </>;
    }

    /**
     * Renders the group name as a label for the new schedule UI.
     */
    private renderGroupNameFREUI() {
        return <div data-automation-id={ AutomationUtils.getAutomationId("group", "QAIDGroupName") } className={ styles.tagNameFRE }>
            { this.getTagName() || this._commonStrings.get("unnamedGroup") }
        </div>;
    }

    /**
     * Renders the group name text field for editing the group name
     */
    private renderGroupNameTextField() {
        const viewState = this.props;
        const tabIndex = viewState.isNotFocusable ? -1 : 0;
        return (
            <>
                <TextField
                    autoFocus={ this.autoFocusOnTag() }
                    className={ AutomationUtils.getAutomationId("group", "QAIDGroupNameTextField") }
                    tabIndex={ tabIndex }
                    placeholder={ viewState.editEnabled ? this._strings.get("enterGroupName") : "" }
                    ariaLabel={ this._strings.get("groupNameFieldAriaLabel") }
                    borderless={ true }
                    defaultValue={ this.getTagName() }
                    disabled={ viewState.tag === null || !viewState.editEnabled || viewState.isNotFocusable }  // The default tag's name cannot be edited.
                    value={ undefined }
                    maxLength={ TAGNAME_CHAR_LIMIT }
                    onChange={ this.onTagNameTextFieldChanged }
                    onFocus={ this.onTagNameTextFieldFocus }
                    onBlur={ () => {} } // Don't save on blur for new UI as the dialog is shown
                    onKeyPress={ this.onTagNameTextFieldKeyPress } />
                { ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftsTargetingHelperText) && <div className={ styles.renameGroupHelperText }>{ this._strings.get("renameGroupHelperText") }</div> }
            </>
        );
    }

    /**
     * Renders the expand/collapse button of the group
     */
    private renderCollapseButton() {
        const viewState = this.props;
        const collapseButtonAriaLabel = this._strings.get("collapseTagAriaLabelFRE");
        const expandButtonAriaLabel = this._strings.get("expandTagAriaLabelFRE");
        const collapseGroupAriaLabel = viewState.isTagCollapsed
            ? expandButtonAriaLabel.format((this.getTagName() || this._commonStrings.get("unnamedGroup")))
            : collapseButtonAriaLabel.format((this.getTagName() || this._commonStrings.get("unnamedGroup")));

        const iconExpanded = "teams-triangle-down";
        const iconCollapsed = "teams-triangle-right";
        return !viewState.hideCollapseButton &&
            <Icon
                ariaLabel={ collapseGroupAriaLabel }
                tabIndex={ 0 }
                data-is-focusable='true'
                data-automation-id={ AutomationUtils.getAutomationId("scheduler", "QAIDCollapseIcon") }
                className={ classNames(styles.collapseIconFRE, "no-print") }
                iconName={ viewState.isTagCollapsed ? iconCollapsed : iconExpanded }
                aria-pressed={ viewState.isTagCollapsed }
                role={ "button" }
                onClick={ this.onTagCollapsed }
                onKeyDown={ this.onTagCollapseKeyDown } />;
    }

    /**
     * Renders the total working hours of the group
     */
    private renderGroupHours() {
        const viewState = this.props;
        const hoursString = this._commonStrings.get("hoursShortFormat").format(DateUtils.getHoursRoundedForDisplay(viewState.hours));
        const groupTotalStyle = styles.groupTotalFRE;
        return <div className={ groupTotalStyle }>
            {
                    !viewState.hideGroupTotal &&
                        <span className={ classNames(styles.groupTotalText) }>
                            { hoursString }
                        </span>
                }
            </div>;
    }

    render() {
        const viewState = this.props;
        const tagHeaderElementId = calculateTagHeaderElementId(this.getTagId());
        const ariaProps: AriaProperties = {
            role: AriaRoles.rowheader,
            label: this.getTagHeaderAriaLabel()
        };

        return <FocusZone
                    isCircularNavigation={false}
                    handleTabKey={FocusZoneTabbableElements.all}
                    direction={FocusZoneDirection.horizontal}
                    id={ tagHeaderElementId }
                    className={ styles.tagGroupHeader }
                    {...generateDomPropertiesForAria(ariaProps) }>
                    {
                        this.renderFREUIForGroupNameAndHours()
                    }
                    {
                        // Do not show any options for default tag
                        viewState.tag && viewState.editEnabled &&
                            this.renderGroupSettingsWithNewFRE()
                    }
                </FocusZone>;
    }

    /**
     * Returns true if the focusedTagId in the TagStore is the tagId of this TagHeader
     */
    private autoFocusOnTag = () => {
        return this.props.tag && (TagStore().focusedTagId === this.props.tag.id);
    }

    /**
     * Change the local value that tracks the name of the tag
     */
    private onTagNameTextFieldChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setTagName(this._tagHeaderViewState, newValue);
    }

    /**
     * Callback fired when the text field receives focus. Sets the static _textFieldHasFocus member to true
     */
    private onTagNameTextFieldFocus = () => {
        TagHeader._textFieldHasFocus = true;
        this.setHoverColumnVisible();
    }

    /**
     * Callback fired when the text field loses focus. Sets the static _textFieldHasFocus member to false
     */
    private onTagNameTextFieldBlur = () => {
        TagHeader._textFieldHasFocus = false;
        this.setHoverColumnVisible();
        // If we autofocused on this tag, clear the focused tag so that we don't autofocus on this again
        if (this.autoFocusOnTag()) {
            setIsFocusedTag("");
        }
        this.saveCurrentTagName();
    }

    /**
     *  Callback fired on text field key press to shift focus to add member.
     */

    private onTagNameTextFieldKeyPress = (key: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isEnterPressed(key)) {
            key.stopPropagation();
            this.saveCurrentTagName();
            this._async.setTimeout(() => {
                if (this._addMember && this._addMember.current) {
                    this._addMember.current.focus();
                }
            }, 0);
        }
    }

    private clearTagNameError = () => {
        setTagNameError(this._tagHeaderViewState, "");
    }

    /**
     * Raise rename tag action with the current value from the text field
     */
    private async saveCurrentTagName() {
        // allow empty string but not null or undefined for this._tagHeaderViewState.tagName.  Don't save if no changes
        if (this.props.tag && (this._tagHeaderViewState.tagName != null) && (this._tagHeaderViewState.tagName !== this.props.tag.name)) {
            const updatedTag = TagUtils.createCloneWithNewTagName(this.props.tag.id, this._tagHeaderViewState.tagName);
            if (updatedTag) {
                try {
                    // save the tag
                    let savedTag = await TagDataService.saveTag(updatedTag, false /* isNewTag */, true /* optimistic */, false /* setIsFocused */, false /* updateCacheOnly */, true /* throwError */);
                    if (savedTag) {
                        // when the tagHeaderView state is updated with new value, scheduleHeaderViewStore filteredTags data needs to be updated with new values as well.
                        updateFilteredTags(savedTag);
                        fireAccessibilityAlert(this._strings.get("groupNameSaved"));
                    }
                } catch (error) {
                    const httpError = error as StaffHubHttpError;
                    if (httpError && httpError.staffHubInnerErrorCode && httpError.staffHubInnerErrorCode === StaffHubErrorCodes.TagNameTaken) {
                        // Error when same name exists
                        setTagNameError(this._tagHeaderViewState, this._strings.get("groupNameExistsError"));
                        fireAccessibilityAlert(this._strings.get("groupNameExistsError"));
                    } else {
                        // Any generic error
                        setTagNameError(this._tagHeaderViewState, this._strings.get("groupNameError"));
                        fireAccessibilityAlert(this._strings.get("groupNameError"));
                    }

                    // Clear the time out
                    this._async.setTimeout(() => {
                        this.clearTagNameError();
                    }, TAGNAME_ERROR_TIMEOUT);
                }
            }
        }
    }

    /**
     * Callback fired when the mouse enters the div wrapping the text field. Sets the private _isMouseOverTextField member to true
     */
    private onMouseEnterTextField = () => {
        this._isMouseOverTextField = true;
        this.setHoverColumnVisible();
    }

    /**
     * Callback fired when the mouse leaves the div wrapping the text field. Sets the private _isMouseOverTextField member to false
     */
    private onMouseLeaveTextField = () => {
        this._isMouseOverTextField = false;
        this.setHoverColumnVisible();
    }

    /**
     * Show or hide the hover column
     */
    private setHoverColumnVisible() {
        setHoverColumnHidden(schedulesViewStateStore(), this.isMouseOverTextFieldOrHasFocus());
    }

    /**
     * Returns true if any of the TagHeader text fields have focus or if this instance has the mouse over the text field
     */
    private isMouseOverTextFieldOrHasFocus() {
        return this._isMouseOverTextField || TagHeader._textFieldHasFocus;
    }

    /**
     * Render the action icons for the group with new FRE changes
     */
    private renderGroupSettingsWithNewFRE() {
        const viewState = this.props;
        const tabIndex = viewState.isNotFocusable ? -1 : 0;
        const addMemberDisableString = ScheduleGridUtils.isYourShiftsView(viewState.employeeViewType) ? this._strings.get("addMemberDisableButtonInYourShifts") : this._strings.get("addMemberDisableButtonInFilter");

        return (
                <div className={ styles.groupSettings }>
                    {
                        // Add member option is shown only for active tags and not in shift overview.
                        TagUtils.isActiveTag(viewState.tag) &&
                            <TooltipHost
                                content={ viewState.disableAddMember ? addMemberDisableString : this._strings.get("addMemberLabel") }
                                hostClassName={ styles.openShiftsInfoTooltipHost }
                                directionalHint={ DirectionalHint.topCenter }
                                calloutProps={ { isBeakVisible: false,
                                gapSpace: parseInt(cssExports.tooltipGapSpace),
                                // ariaLabel doesn't work here as the focus doesn't go on the tooltip. Add aria label for the icon instead
                                className: styles.openShiftsInfoCallout } }
                                styles={ {
                                    root: styles.toolTipRoot
                                } }>
                                    <CommandBarButton tabIndex={ tabIndex }
                                        text={ this._strings.get("addPeopleTitle") }
                                        checked={ false }
                                        allowDisabledFocus={ true }
                                        disabled={ viewState.disableAddMember }
                                        componentRef={ this._addMember }
                                        className={ classNames(styles.settingIcon, styles.transparentButton, styles.notRound) }
                                        ariaLabel={ viewState.disableAddMember ? addMemberDisableString : this._strings.get("addTeamMemberToTag") }
                                        onClick={ this.onAddMemberToTagClicked }
                                        iconProps={ { className: classNames(StaffHubTourMarker.addMember ,  { [styles.disableIcon]: viewState.disableAddMember }, "no-print"), iconName: "teams-add-member" } }
                                        styles={ {icon: styles.buttonIcon, root: styles.buttonRoot} }
                                        data-automation-id={ AutomationUtils.getAutomationId("scheduler", "QAIDAddMemberToGroup") } />
                            </TooltipHost>
                    }
                </div>
        );
    }

    /**
     * Render the more icon for rename, reorder, delete group options
     */
    private renderMoreIcon() {
        const viewState = this.props;
        const reorderMemberDisableString = ScheduleGridUtils.isYourShiftsView(viewState.employeeViewType) ? this._strings.get("reorderMemberDisableButtonInYourShifts") : this._strings.get("reorderMemberDisableButtonInFilter");
        const deleteGroupDisableString = ScheduleGridUtils.isYourShiftsView(viewState.employeeViewType) ? this._strings.get("deleteGroupDisableButtonInYourShifts") : this._strings.get("deleteGroupDisableButtonInFilter");
        const reorderGroupLabel = viewState.disableReorderGroup ? reorderMemberDisableString : this._strings.get("reorderTags");
        const deleteGroupLabel = viewState.disableDeleteGroup ? deleteGroupDisableString : this._strings.get("deleteTag");
        const renameMenuItemProps = getRenameGroupMenuProps();
        const deleteMenuItemProps = getDeleteGroupMenuProps(this.props);

        return <IconButton
                    role="menuitem"
                    title={ this._strings.get("moreOptionsLabel") }
                    ariaLabel={ this._strings.get("moreOptionsLabel") }
                    className={ classNames(styles.settingIcon, styles.transparentButton, styles.moreIcon) }
                    styles={ { menuIcon: styles.buttonIcon } }
                    data-automation-id={ AutomationUtils.getAutomationId("scheduler", "QAIDMoreMenuGroupHeader") }
                    componentRef={ this._moreMenuButton }
                    menuIconProps={ { iconName: "teams-more-svg" } }
                    menuProps={ {
                        items: [
                            {
                                key: "rename-group",
                                className: classNames(styles.overflowMenuItem),
                                iconProps: {
                                    className: classNames(styles.settingIcon, styles.overflowMenuItemIcon, AutomationUtils.getAutomationId("scheduler", "QAIDRename")),
                                    iconName: "teams-edit"
                                },
                                title: this._strings.get("renameGroupLabel"),
                                ariaLabel: this._strings.get("renameGroupLabel"),
                                text: this._strings.get("renameGroupLabel"),
                                onClick: this.onRenameGroupClicked,
                                "data-automation-id": AutomationUtils.getAutomationId("scheduler", "QAIDRenameGroupMenuOption"),
                                ...renameMenuItemProps
                            },
                            {
                                key: "reorder-group",
                                className: classNames(styles.overflowMenuItem),
                                iconProps: {
                                    className: classNames(styles.settingIcon, styles.overflowMenuItemIcon, AutomationUtils.getAutomationId("scheduler", "QAIDReOrder")),
                                    iconName: "teams-drag-drop-offset"
                                },
                                ariaLabel: reorderGroupLabel,
                                text: this._strings.get("reorderTags"),
                                title: reorderGroupLabel,
                                onClick: this.onReorderTagsClicked,
                                disabled: viewState.disableReorderGroup,
                                "data-automation-id": AutomationUtils.getAutomationId("scheduler", "QAIDReorderGroupMenuOption")
                            },
                            {
                                key: "delete-group",
                                className: classNames(styles.overflowMenuItem, "DeleteGroupMenuOption"),
                                iconProps: {
                                    className: classNames(styles.settingIcon, styles.overflowMenuItemIcon, AutomationUtils.getAutomationId("scheduler", "QAIDDeleteTag")),
                                    iconName: "teams-delete-svg"
                                },
                                ariaLabel: deleteGroupLabel,
                                title: deleteGroupLabel,
                                text: this._strings.get("deleteTag"),
                                onClick: this.onDeleteGroupClicked,
                                "data-automation-id": AutomationUtils.getAutomationId("scheduler", "QAIDDeleteTag"),
                                ...deleteMenuItemProps
                            }
                        ]
                    }}
                />;
    }

    /**
     * Callback when key down is detected on the collapse tag button. Calls onTagCollapsed if the key is an action key
     */
    private onTagCollapseKeyDown = (key: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isActionKeyPressed(key)) {
            key.preventDefault();
            this.onTagCollapsed();
        }
    }

    /**
     * Click handler when the arrow in tag name is clicked
     */
    private onTagCollapsed = () => {
        const viewState = this.props;
        const tagId = this.getTagId();
        if (tagId) {
            toggleTagView(schedulesViewStateStore(), tagId, !viewState.isTagCollapsed /* newCollapseState */);
        }
    }

    /**
     * Sets focus on the more menu icon button
     */
    private setFocusOnMoreMenuButton = () => {
        this._async.setTimeout(() => {
            if (this._moreMenuButton && this._moreMenuButton.current) {
                this._moreMenuButton.current.focus();
            }
        }, 0);
    }

    private setFocusOnTeamPicker = () => {
        this._async.setTimeout(() => {
            const teamPickerIconElement = document.getElementById(TEAM_PICKER_ICON_ID);
                if (teamPickerIconElement) {
                    teamPickerIconElement.focus();
                }
        }, 0);
    }
    /**
     * Click handler for rename group
     */
    private onRenameGroupClicked = () => {
        InstrumentationService.logEvent(InstrumentationService.events.RenameGroupClicked,
            [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView,
                InstrumentationUtils.getCurrentViewForInstrumentation(this.props.scheduleCalendarType))]);

        // Cache existing group name
        const oldGroupName = this.getTagName();
        showConfirmDialog(
           this._strings.get("renameGroupLabel"),
            "" /** No subtitle */,
            {
                bodyElement: (this.renderGroupNameTextField()),
                isBlocking: true,
                containerClassName: classNames(AutomationUtils.getAutomationId("confirm", "QAIDConfirmDialog"), styles.confirmDialog),
                okText: this._commonStrings.get("save")
            },
            () => {
                this.saveCurrentTagName();
                this.setFocusOnMoreMenuButton();
                InstrumentationService.logEvent(InstrumentationService.events.RenameDialogOkClicked,
                    [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView,
                        InstrumentationUtils.getCurrentViewForInstrumentation(this.props.scheduleCalendarType))]);
            } /** onOK */,
            () => {
                // On clicking cancel, revert to old groupname
                setTagName(this._tagHeaderViewState, oldGroupName);
                this.saveCurrentTagName();
                this.setFocusOnMoreMenuButton();
                InstrumentationService.logEvent(InstrumentationService.events.RenameDialogCancelClicked,
                    [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView,
                        InstrumentationUtils.getCurrentViewForInstrumentation(this.props.scheduleCalendarType))]);
            } /** onCancel */,
            false /** Ok button disabled */,
            true /** Show close button */,
            true /** Call cancel on dismiss */);
    }

    /**
     * Click handler for reorder tags button
     */
    private onReorderTagsClicked = () => {
        setIsReorderTagsPanelOpen(schedulesViewStateStore(), true /* isReorderTagsPanelOpen */);
    }

    /**
     * Click handler for add member to tag
     */
    private onAddMemberToTagClicked = () => {
        if (this.props.tag) {
            setMemberDialogOpen(true);
            setMemberOpenDialogTriggerButton(this._addMember?.current ?? null);
            setMemberPanelValues(schedulesViewStateStore(), this.props.tag.id, null /* member */);
        }
    }

    private setGlobalErrorMessage(groupName: string) {
        setGlobalMessages(appViewState().globalMessageViewState, [this._strings.get("groupNotDeleted").format(groupName)], MessageBarType.error, null /* action button title */, null /* action button callback */, false /* auto dismiss */, true /* is multiline */);
    }

    /**
     * Click handler for deleting a group
     */
    private onDeleteGroupClicked = () => {
        let viewState = this.props;
        if (viewState.tag) {
            InstrumentationService.logEvent(InstrumentationService.events.GroupDeleteOnScheduleClicked,
                [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, InstrumentationUtils.getCurrentViewForInstrumentation(this.props.scheduleCalendarType))]);

            // If group has no name then show default name as Unnamed group
            let groupName = viewState.tag.name || this._commonStrings.get("unnamedGroup");

            showConfirmDialog(
                this._strings.get("deleteTag"),
                viewState.hasShifts
                    ? this._strings.get("deleteGroupMessageWithShifts").format(groupName)
                    : this._strings.get("deleteGroupMessage").format(groupName),
                {
                    okText: viewState.hasShifts ? "" : this._commonStrings.get("remove"),
                    isBlocking: true,
                    containerClassName: styles.confirmDialog
                },
                () => {
                    if (!viewState.hasShifts) {
                        try {
                            InstrumentationService.logEvent(InstrumentationService.events.GroupCommitDeleteOnScheduleClicked,
                                [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, InstrumentationUtils.getCurrentViewForInstrumentation(this.props.scheduleCalendarType))]);
                            TagDataService.deleteTag(viewState.tag)
                            .then((tag: ITagEntity) => {
                                const addGroupElement = document.getElementById("addGroup");
                                if (addGroupElement) {
                                    addGroupElement.focus();
                                }
                                if (tag) {
                                    fireAccessibilityAlert(this._strings.get("groupDeleted").format(groupName));
                                } else {
                                    this.setGlobalErrorMessage(groupName);
                                }
                            });
                        } catch (error) {
                            this.setGlobalErrorMessage(groupName);
                        }
                        this.setFocusOnTeamPicker();
                    }
                },
                () => {
                    this.setFocusOnMoreMenuButton();
                },
                false /** Ok button disabled */,
                false /** Show close button */,
                true /** Call cancel on dismiss */);
       }
    }
}