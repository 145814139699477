import { DefaultButton, Icon, PrimaryButton } from "@fluentui/react";
import { getStrings } from "@microsoft/shifts-experiences-shared";
import * as moment from "moment";
import { Moment } from "moment";
import * as React from "react";
import { ShiftRequestUtils } from "sh-application";
import WarningIndicator from "sh-application/components/common/WarningIndicator";
import { InstrumentScheduleEventFunction } from "sh-application/components/schedules/Schedules";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import DateTimeFormatter from "sh-application/utility/DateTimeFormatter";
import DateUtils from "sh-application/utility/DateUtils";
import InstrumentationUtils from "sh-application/utility/InstrumentationUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import { InstrumentationEventPropertyInterface, getGenericEventPropertiesObject } from "sh-instrumentation";
import {
    FlightKeys,
    IBaseShiftEntity,
    IBreakEntity,
    IOpenShiftEntity,
    IShiftEntity,
    ISubshiftEntity,
    ScheduleCalendarType,
    ShiftRequestType, ShiftRequestTypes
} from "sh-models";
import { FlightSettingsService, InstrumentationService } from "sh-services";
import StringsStore from "sh-strings/store";
import { TeamStore } from "sh-team-store";

import MemberUtils from "../../../utility/MemberUtils";
import { UserPersona } from "../../common/UserPersona";

import { getAddedByUserPersonaProps, getCrossLocationTeamNameProps } from "./ShiftDetails.mappers";
import requestOpenShift from "./lib/actions/requestOpenShift";
import { CrossLocationTeamName } from "./CrossLocationTeamName";

const styles = require("./ShiftDetails.scss");
const classNames = require("classnames/bind");
const MAX_SHIFT_DETAILS_CONTENT_HEIGHT = 280;
const SHIFT_DETAILS_PREFIX = "shift-details-";

export interface ShiftDetailsProps {
    /* Shift that provides shift information to the ShiftDetails */
    shift: IShiftEntity;

    /* navihate to the shift request page */
    navigateToRequestPage: (initialRequestId?: string, shiftId?: string, requestType?: ShiftRequestType) => void;

    /* Helper function to instrument scheduling events */
    instrumentScheduleEvent: InstrumentScheduleEventFunction;

    /* true if the current user is an admin */
    isAdmin: boolean;

    /* optional - className to use for the shift details container*/
    className?: string;

    /* optional - true if the shift details should render a conflict UI */
    doesConflict?: boolean;

    /* optional - the request associated with this shift */
    requestId?: string;

    /* optional - is it currently saving */
    isSaving?: boolean;

    /* optional - callback to pass as onFinish to requestOpenShift */
    onFinishRequesting?: VoidFunction;

    scheduleCalendarType: ScheduleCalendarType; // needed for instrumentation
}

/**
 * ShiftDetails - renders information about the provided shift
 */
export default class ShiftDetails extends React.Component<ShiftDetailsProps, any> {
    private _strings: Map<string, string>;
    private _shiftReqStrings: Map<string, string>;
    private _commonStrings: Map<string, string>;

    constructor(props: ShiftDetailsProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("shiftDetails").strings;
        this._shiftReqStrings = StringsStore().registeredStringModules.get("shiftRequests").strings;
        this._commonStrings = StringsStore().registeredStringModules.get("common").strings;

        this.state = {
            showBottomShadowDiv: false,
            showTopShadowDiv: false
        };
    }

    /**
     * Renders a span containing a localized title for the ShiftDetails component
     */
    private renderCalloutTitle(): JSX.Element {
        return <span data-automation-id={ AutomationUtils.getAutomationId("shiftDetails", "QAIDTitle") } className={ styles.shiftDetailsTitle }>{ this._strings.get("shiftDetailsTitle") }</span>;
    }

    /**
     * When the ShiftDetails component has no shift details to display, it instead renders some text as an
     * empty state
     */
    private renderEmptyState(): JSX.Element {
        return <div className={ styles.emptyClassContainer } data-automation-id={ AutomationUtils.getAutomationId("shiftDetails", "QAIDEmptyState") }>{ this._strings.get("emptyShiftDetailsText") }</div>;
    }

    /**
     * Renders an icon and the shift's notes
     * @param shift
     */
    private renderShiftNotes(shift: IBaseShiftEntity): JSX.Element {
        return (
            <div data-automation-id={ AutomationUtils.getAutomationId("shiftDetails", "QAIDNotes") } className={ styles.shiftDetail } >
                <Icon className={ styles.shiftDetailIcon } iconName={ "teams-notes" }/>
                <div className={ classNames(styles.shiftDetailTextContent, styles.shiftNotes) }>
                    { shift.notes }
                </div>
            </div>
        );
    }

    /**
     * Returns a list of shift break elements to render
     * @param shift
     */
    private renderShiftBreaks(shift: IBaseShiftEntity): JSX.Element[] {
        return shift.breaks.map(this.renderBreak);
    }

    /**
     * Renders an individual shift break element. Will not render if duration is 0.
     * @param shiftBreak Shift Break.
     * @returns JSX.Element if duration valid, null if not.
     */
    private renderBreak = (shiftBreak: IBreakEntity): JSX.Element | null => {
        const now = moment();

        return shiftBreak.duration > 0 ? (
            <div
                data-automation-id={AutomationUtils.getAutomationId("shiftDetails", "QAIDBreak")}
                className={styles.shiftDetail}
                key={shiftBreak.id}>
                <Icon className={styles.shiftDetailIcon} iconName={"teams-cup"} />
                <div className={styles.breakContainer}>
                    <div className={styles.shiftDetailTextContent}>
                        <span>{this._commonStrings.get("breakLabel")}</span>
                        <span
                            className={styles.breakDuration}
                            data-automation-id={AutomationUtils.getAutomationId("shiftDetails", "QAIDBreakDuration")}>
                            {DateTimeFormatter.getTimeDurationForDisplay(now, now.clone().add(shiftBreak.duration, "minutes"))}
                        </span>
                    </div>
                </div>
            </div>
        ) : null;
    };

    /**
     * Renders an individual activity element
     * @param activity
     */
    private renderActivity = (activity: ISubshiftEntity) => {
        return (
            <div data-automation-id={ AutomationUtils.getAutomationId("shiftDetails", "QAIDActivity") } key={ activity.id } className={ classNames(styles.shiftDetail, styles.activity) }>
                <div className={ classNames(styles.activityBar, styles[activity.theme]) }></div>
                <div className={ styles.activityContent }>
                    {
                        activity.title &&
                            <div>{ activity.title }</div>
                    }
                    <div className={ styles.activityTimeRange } >{ DateTimeFormatter.getEventTimeRangeAsString(activity.startTime, activity.endTime) }</div>
                </div>
            </div>
        );
    }

    /**
     * Returns a list of activity elements to render
     * @param shift
     */
    private renderShiftActivities(shift: IBaseShiftEntity): JSX.Element[] {
        return shift.subshifts.sort((a, b) => a.startTime.isBefore(b.startTime) ? -1 : 1).map(this.renderActivity);
    }

    /**
     * Swap and offer buttons to deep link to Requests tab
     * @param shift
     */
    private renderActionButtons(shift: IBaseShiftEntity) {
        const disableSwapAndHandOffRequests: boolean = FlightSettingsService.isFlightEnabled(FlightKeys.DisableSwapAndHandOffRequests);
        const isRequestTypeEligible: boolean = TeamStore() && TeamStore().team ? true : false;
        if (disableSwapAndHandOffRequests) {
            return null;
        }

        return ShiftUtils.isCurrentUserShift(shift) &&
            <div className={ styles.actionButtonsContainer }>
               {
                   (isRequestTypeEligible && ShiftRequestUtils.isRequestTypeEligible(TeamStore().team, ShiftRequestTypes.Swap))
                    &&
                        <PrimaryButton role="menuitem" text={ this._shiftReqStrings.get("swap") } onClick={ this.swapClicked } />
               }
               {
                    (isRequestTypeEligible && ShiftRequestUtils.isRequestTypeEligible(TeamStore().team, ShiftRequestTypes.HandOff))
                    &&
                        <PrimaryButton role="menuitem" text={ this._shiftReqStrings.get("handOff") } className={ styles.offerButton } onClick={ this.offerClicked } />
               }
            </div>;
    }

    /**
     * Callback for swap click
     */
    private swapClicked = () => {
        const { navigateToRequestPage, scheduleCalendarType} = this.props;

        if (navigateToRequestPage) {
            navigateToRequestPage(null, this.props.shift.id, ShiftRequestTypes.Swap);

            InstrumentationService.logEvent(InstrumentationService.events.RequestFlyoutSwapClicked,
                [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, InstrumentationUtils.getCurrentViewForInstrumentation(scheduleCalendarType))]);
        }
    }

    /**
     * Callback for offer click
     */
    private offerClicked = () => {
        const { navigateToRequestPage, scheduleCalendarType} = this.props;

        if (navigateToRequestPage) {
            navigateToRequestPage(null, this.props.shift.id, ShiftRequestTypes.HandOff);

            InstrumentationService.logEvent(InstrumentationService.events.RequestFlyoutOfferClicked,
                [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, InstrumentationUtils.getCurrentViewForInstrumentation(scheduleCalendarType))]);
        }
    }

    /**
     * Renders the display name of the owner of the shift
     * @param shift
     */
    private renderMemberDisplayName(): JSX.Element {
        const { shift } = this.props;
        return (
            <div data-automation-id={ AutomationUtils.getAutomationId("shiftDetails", "QAIDMemberName") } className={ styles.shiftMember }>{ MemberUtils.getDisplayNameFromMemberId(shift.memberId) }</div>
        );
    }

    /**
     * Renders the shift's start and end time in localized format with an icon
     * @param shift
     */
    private renderShiftTimeOrTitle(): JSX.Element {
        const { shift } = this.props;
        return (
            <div data-automation-id={ AutomationUtils.getAutomationId("shiftDetails", "QAIDTime") } className={ styles.shiftTitle }>{ DateTimeFormatter.getShiftRequestDateTimeRangeAsString(shift.startTime, shift.endTime) }</div>
        );
    }

    /**
     * Returns true if there are no shift details to render
     * shift with no title, no notes, no breaks and no activities should render the empty state
     * @param shift
     */
     private shouldRenderEmptyState(shift: IBaseShiftEntity): boolean {
        return !shift.title && !this.shiftHasBreaks(shift.breaks) && shift.subshifts.length === 0 && !shift.notes;
    }

    /**
     * Check if there is any break with duration more than 0.
     * @param breaks Shift breaks collection.
     * @returns true if the Shifts contains any break with duration greater than 0
     */
    shiftHasBreaks = (breaks: IBreakEntity[]): boolean =>
        breaks.reduce((total: number, shiftBreak: IBreakEntity) => (total + shiftBreak.duration), 0) > 0;

    /**
     * Renders a container div and shift-specific content that overflows and
     * creates a scrollbar if the content is too large
     */
    private renderScrollableContent(): JSX.Element {
        const { shift } = this.props;
        return (<>
            { this.state.showBottomShadowDiv && <div className={ classNames(styles.boxShadowTopDiv, { [`${styles.boxshadowTop}`]: this.state.showTopShadowDiv }) } ></div> }
            <div onScroll={ this.onScrollStart } id={ SHIFT_DETAILS_PREFIX + shift.id } className={ classNames(styles.scrollableContent, {[styles.shortenedContent]: this.shiftDoesConflict()}) }>
                { shift.notes && this.renderShiftNotes(shift) }
                { shift.breaks && shift.breaks.length > 0 && this.renderShiftBreaks(shift) }
                { this.renderShiftActivities(shift) }
            </div>
            { this.state.showBottomShadowDiv && <div className={ classNames(styles.boxShadowBottomDiv, { [`${styles.boxshadowBottom}`]: this.state.showBottomShadowDiv }) } ></div> }
            </>
        );
    }

    /**
     * Call back when user starts to scroll, show the top shadow div
     */
    onScrollStart = () => {
        const element = document.getElementById(SHIFT_DETAILS_PREFIX + this.props.shift.id);
        if (element && element.scrollTop > 2) {
            this.setState({
                showTopShadowDiv: true
            });
        } else {
            this.setState({
                showTopShadowDiv: false
            });
        }
    }

    /**
     * Should show the make request button for open shifts that haven't yet been requested
     */
    private shouldShowMakeRequestButton(): boolean {
        const { shift } = this.props;
        return ShiftUtils.isOpenShift(shift) && !this.shouldViewRequest();
    }

    /**
     * Determine if this shift has already begun.  This will cause the make request button to be disabled
     */
    private hasShiftStarted(): boolean {
        const { shift } = this.props;
        const currentTime: Moment = moment();
        return shift.startTime && shift.startTime.isSameOrBefore(currentTime);
    }

    /**
     * The ShiftDetail component should allow users to view shift requests associated with this component's
     * shift if there are any
     */
    private shouldViewRequest(): boolean {
        return !!this.props.requestId;
    }

    /**
     * Returns true if the shift has a conflict
     */
    private shiftDoesConflict(): boolean {
        return this.props.doesConflict;
    }

    /**
     * Get the warning message or null that is associated with this shift.
     * We should only ever show one message.
     */
    private getWarningMessage(): string {
        let warningMessage: string = null;
        if (this.shouldShowMakeRequestButton() && this.hasShiftStarted()) {
            warningMessage = this._strings.get("pastOpenShiftWarning");
        } else if (this.shiftDoesConflict()) {
            warningMessage = this._strings.get("shiftConflictLabel");
        }
        return warningMessage;
    }

    /**
     * May render a button container div with an appropriate button (view or make request) and/or the conflict indicator
     */
    private renderButtonAndConflict(): JSX.Element {
        const { isSaving } = this.props;

        const shouldRenderViewRequestButton: boolean = this.shouldViewRequest();
        const shouldRenderMakeRequestButton: boolean = this.shouldShowMakeRequestButton();
        const doesConflict: boolean = this.shiftDoesConflict();
        const shouldDisableRequestButton: boolean = this.hasShiftStarted() || isSaving;
        const buttonClass: string = styles.actionableButton;
        const makeRequestButtonText: string = isSaving ? this._strings.get("requestingShiftLabel") : this._strings.get("requestShiftLabel");
        // TODO: improve how "context menu" is added to these aria lables. This is a temporary fix, limited by how the dialog is structured.
        const makeRequestButtonAriaText: string = this._commonStrings.get("contextMenuAriaLabel") + " " + this._strings.get("requestShiftAriaLabel");
        const viewRequestButtonText: string = this._strings.get("viewRequestedShiftLabel");
        const viewRequestButtonAriaText: string = this._commonStrings.get("contextMenuAriaLabel") + " " + this._strings.get("viewRequestedShiftAriaLabel");
        const warningMessage: string = this.getWarningMessage();
        const hasWarningMessage: boolean = !!warningMessage;

        return (
             <>
                 {
                    (shouldRenderMakeRequestButton || shouldRenderViewRequestButton || doesConflict) &&
                        <div className={ classNames(styles.buttonContainer, {[styles.lengthenedContent]: hasWarningMessage}) }>
                            {
                                hasWarningMessage &&
                                    <WarningIndicator qaid={ AutomationUtils.getAutomationId("shiftDetails", "QAIDWarningMessage") } className={ styles.conflictIndicator } text={ warningMessage } />
                            }
                            {
                                shouldRenderViewRequestButton &&
                                    <DefaultButton data-automation-id={ AutomationUtils.getAutomationId("shiftDetails", "QAIDViewRequest") } ariaLabel={ viewRequestButtonAriaText } className={ buttonClass } onClick={ this.onViewRequestButtonClick }>
                                        { viewRequestButtonText }
                                    </DefaultButton>
                            }
                            {
                                shouldRenderMakeRequestButton &&
                                    <PrimaryButton data-automation-id={ AutomationUtils.getAutomationId("shiftDetails", "QAIDMakeRequest") } ariaLabel={ makeRequestButtonAriaText } className={ buttonClass } onClick={ this.onMakeRequestButtonClick } disabled={ shouldDisableRequestButton }>
                                        { makeRequestButtonText }{ isSaving && <span className={ styles.progressDots } /> }
                                    </PrimaryButton>
                            }
                        </div>
                 }
            </>
        );
    }

    /**
     * Callback fired on button click of the ShiftDetail component's make request button. Will launch a shift request
     */
    private onMakeRequestButtonClick = () => {
        const { shift, onFinishRequesting, instrumentScheduleEvent } = this.props;

        if (ShiftUtils.isOpenShift(shift)) {
            requestOpenShift(shift as IOpenShiftEntity, onFinishRequesting);
        }

        if (instrumentScheduleEvent) {
            instrumentScheduleEvent(InstrumentationService.events.Requests, this.getSharedInstrumentationValues().concat([
                    getGenericEventPropertiesObject(InstrumentationService.properties.ActionTaken, InstrumentationService.ShiftRequestActionTaken.OpenShiftRequest),
                    getGenericEventPropertiesObject(InstrumentationService.properties.HoursFromOpenShiftRequested, DateUtils.getDifferenceInHoursFromMoments(shift.startTime, moment(), false /*precise*/))
                ]));
        }
    }

    /**
     * Callback fired on button click of the ShiftDetail component's view request button. Will open the shift requests panel
     */
    private onViewRequestButtonClick = () => {
        const { navigateToRequestPage, requestId, instrumentScheduleEvent } = this.props;

        if (navigateToRequestPage) {
            navigateToRequestPage(requestId);
        }

        if (instrumentScheduleEvent) {
            instrumentScheduleEvent(InstrumentationService.events.Requests, this.getSharedInstrumentationValues().concat([
                    getGenericEventPropertiesObject(InstrumentationService.properties.ViewRequestClicked, true)
                ]));
        }
    }

    /**
     * Returns a list of shared instrumenation properties used by view request and make request instrumentation flows.
     * Note: this assumes that we are working with an open shift, the only case at the moment
     */
    private getSharedInstrumentationValues(): InstrumentationEventPropertyInterface[] {
        const { shift, isAdmin } = this.props;
        return [
            getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.RequestActionClicked),
            getGenericEventPropertiesObject(InstrumentationService.properties.RequestType, InstrumentationService.ShiftRequestTypes.OpenShifts),
            getGenericEventPropertiesObject(InstrumentationService.properties.ActedOnBy, InstrumentationService.ShiftRequestUsertype.Sender),
            getGenericEventPropertiesObject(InstrumentationService.properties.NumberSlot, (shift as IOpenShiftEntity).openSlots),
            getGenericEventPropertiesObject(InstrumentationService.properties.HasDifferentDraftChanges, ShiftUtils.shiftHasUnsharedEdits(shift)),
            getGenericEventPropertiesObject(InstrumentationService.properties.IsAdmin, isAdmin)
        ];
    }

    componentDidMount() {
        const element = document.getElementById(SHIFT_DETAILS_PREFIX + this.props.shift.id);
        if (element && element.scrollHeight > MAX_SHIFT_DETAILS_CONTENT_HEIGHT) {
            this.setState({
                showBottomShadowDiv: true
            });
        }

        InstrumentationService.logEvent(InstrumentationService.events.RequestFlyoutOpened,
            [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, InstrumentationUtils.getCurrentViewForInstrumentation(this.props.scheduleCalendarType))]);
    }

    private renderShiftContent(): React.ReactNode {
        const { shift } = this.props;
        const addedByProps = getAddedByUserPersonaProps(this.props);
        const teamNameProps = getCrossLocationTeamNameProps(this.props);

        return <>
            <div className={ styles.shiftTitleContainer }>
                {this.renderShiftTimeOrTitle()}
                {!teamNameProps && shift.memberId ? this.renderMemberDisplayName() : null}
                {teamNameProps ? <CrossLocationTeamName {...teamNameProps} /> : null}
            </div>
            {addedByProps ? getStrings("openShiftsExperience", "addedByText") : null}
            {addedByProps ? <UserPersona {...addedByProps} /> : null}
            {this.shouldRenderEmptyState(shift) ? this.renderEmptyState() : null}
            {this.renderScrollableContent()}
            {
                ShiftUtils.isOpenShift(shift)
                    ? this.renderButtonAndConflict()
                    : ShiftUtils.isWorkingShift(shift) && this.renderActionButtons(shift)
            }
        </>;
    }

    render() {
        const { className } = this.props;

        return (
            <div role="menu" className={ classNames(className, styles.shiftDetailsContainer) } >
                { this.renderCalloutTitle() }
                {this.renderShiftContent()}
            </div>
        );
    }
}