import * as moment from "moment";
import * as React from "react";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import DateUtils from "sh-application/utility/DateUtils";
import StringsStore from "sh-strings/store";
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxProps, IIconProps } from "@fluentui/react";
import { Moment } from "moment";
import { observer } from "mobx-react";
import { TimeRange } from "sh-application/utility/DateUtils";

const styles = require("./TimePicker.scss");
const classNames = require("classnames/bind");

const PERMITTED_TIME_FORMATS = ["MM-DD-YYYY H", "MM-DD-YYYY HH", "MM-DD-YYYY HH:mm", "MM-DD-YYYY H:mm", "MM-DD-YYYY HH:mm A", "MM-DD-YYYY H:mm A", "MM-DD-YYYY HH:mm a", "MM-DD-YYYY H:mm a",   // 24 hr
                                "MM-DD-YYYY h:mm", "MM-DD-YYYY hh:mm", "MM-DD-YYYY h:mm a", "MM-DD-YYYY hh:mm a", "MM-DD-YYYY h:mm A", "MM-DD-YYYY hh:mm A", "MM-DD-YYYY ha", "MM-DD-YYYY hA"];
const PERMITTED_DATE_FORMAT = "MM-DD-YYYY";

const DEFAULT_INTERVAL_MINUTES = 1;

interface TimePickerOption extends IComboBoxOption {
    time: Moment;
    enabled: boolean;
}

export interface TimePickerProps extends Pick<IComboBoxProps, "styles"> {
    /** Text to display above the time picker */
    label?: string;

    /** Aria label for the time picker */
    ariaLabel?: string;

    /** true if current is valid */
    inputValid: boolean;

    allowFreeform?: boolean;

    autoComplete?: "on" | "off";

    className?: string;

    buttonIconProps?: IIconProps;

    /* There are two ways to specify the time range for the time picker.

       One is to provide a startTime and endTime (with an optional interval specified as well
       If the caller does this, the time picker will provide options from the startTime to the endTime, with steps defined by the interval.

       The other way is to provide a list of valid ranges, and a list of invalid ranges. Then the time picker will
       provide enabled options spanning the valid ranges, and disabled options spanning the invalid ranges.

       If ranges are passed in, they will be used rather than the start time
    */

    startTime: Moment;

    endTime?: Moment;

    interval?: number;

    validRanges?: TimeRange[];

    invalidRanges?: TimeRange[];

    endTimeInclusive?: boolean;

    anchorTime?: Moment;

    showAnchorOffset?: Boolean;

    /** (optional) The initial selected time/date in the dropdown */
    selectedTime?: Moment;

    /** (optional) Callback that is invoked when the user changes the selected time */
    onChanged?: (newValue: Moment) => void;

    onInvalid?: Function;

    dataAutomationId?: String;

    autofocus?: boolean;

    /** indicates if this time picker is used in the new full page panel, then it would render different hover states */
    isFullPagePanel?: boolean;

    /** indicates if the time picker is disabled */
    disabled?: boolean;
}

/**
 * TimePicker - A picker of time
 */
@observer
export default class TimePicker extends React.Component<TimePickerProps, {}> {
    private _strings: Map<string, string>;
    private _timePicker = React.createRef<IComboBox>();

    constructor(props: TimePickerProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("timePicker").strings;
    }

    /**
     * When a disabled option is clicked, stop the propagation of the click event so that the time picker does not detect it.
     */
    private onDisabledOptionClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
    }

    /**
     * When a disabled option receives a key press event, stop the propagation of the click event so that the time picker does not detect it.
     */
    private onDisabledOptionKeyPress(e: React.KeyboardEvent<HTMLElement>) {
        e.stopPropagation();
    }

    /**
     * Render a time picker option
     * @param option
     */
    private onRenderOption = (option: TimePickerOption): JSX.Element => {
        if (!option.enabled) {
            return this.renderDisabledOption(option);
        } else {
            return this.renderEnabledOption(option);
        }
    }

    /**
     * Remder an enabled time picker option
     * @param option
     */
    private renderEnabledOption(option: TimePickerOption): JSX.Element {
        const optionStyles = classNames(styles.enabledOption, styles.timePickerOption);
        const optionContainerStyles = classNames( styles.optionContainer,
            AutomationUtils.getAutomationId("addEditShiftPanel", "QAIDTimeContainer"),
            { [`${styles.defaultOptionContainer}`]: !this.props.isFullPagePanel,
              [`${styles.fullpageTimePicker}`]: this.props.isFullPagePanel} );
        return (
            <div  className={ optionContainerStyles }>
                <div className={ optionStyles }>
                    { option.text }
                </div>
                {
                    this.props.anchorTime && this.props.showAnchorOffset && this.renderAnchorTimeOffset(option)
                }
            </div>
        );
    }

    /**
     * Render an disabled time picker option
     * @param option
     */
    private renderDisabledOption(option: TimePickerOption): JSX.Element {
        const optionStyles = classNames(styles.disabledOption,  styles.timePickerOption);
        return (
            <div onKeyPress={ this.onDisabledOptionKeyPress } onClick={ this.onDisabledOptionClick }  className={ classNames(styles.optionContainer, styles.disabledOptionContainer) } >
                <div className={ optionStyles }>
                    { option.text }
                </div>
                {
                    this.props.anchorTime && this.renderAnchorTimeOffset(option)
                }
            </div>
        );
    }

    /**
     * Render the anchor time offset text
     * @param option
     */
    private renderAnchorTimeOffset(option: TimePickerOption): JSX.Element {
        const offset = option.time.diff(this.props.anchorTime, "minutes");
        const offsetString = this._strings.get("anchorOffsetFormatString").format(String(offset));
        return (<div className={ styles.anchorTimeOffset } > { offsetString } </div>);
    }

    private getOptionStartTimeKey(startTime: Moment) {
        return startTime.toISOString();
    }

    /**
     * Construct a set of time picker options spanning the gap between start and endTime
     * @param startTime
     * @param endTime
     * @param interval
     * @param selectedTime
     * @param rangeIsValid
     */
    private getOptionsWithinRange(startTime: Moment, endTime: Moment, interval: number, selectedTime: Moment, rangeIsValid: boolean, comparisonFn: Function): Array<TimePickerOption> {
        let timeArray: Array<TimePickerOption> = [];
        let optionStartTime = startTime.clone();

        while (comparisonFn(optionStartTime, endTime)) {
            const isSelected = selectedTime ? selectedTime.isSame(optionStartTime) : optionStartTime.isSame(startTime);
            const text = DateTimeFormatter.getDateTimeAsString(optionStartTime, DateTimeFormatType.Time);
            timeArray.push(
                {
                    key: this.getOptionStartTimeKey(optionStartTime),
                    title: text,
                    text,
                    time: optionStartTime.clone(),
                    selected: isSelected,
                    enabled: rangeIsValid
                });
            optionStartTime.add(interval || DEFAULT_INTERVAL_MINUTES, "minutes");
        }

        return timeArray;
    }

    /**
     * Returns the time picker options for the time picker dropdown.
     */
    private getTimeOptions(): Array<TimePickerOption> {
        let timeArray: Array<TimePickerOption> = [];
        const { interval, endTime, startTime, selectedTime, anchorTime, endTimeInclusive } = this.props;
        let validRanges = this.props.validRanges ? this.props.validRanges.slice() : [];
        let invalidRanges = this.props.invalidRanges ? this.props.invalidRanges.slice() : [];

        if (startTime && endTime) {
            const comparisonFn = endTimeInclusive ? (optionStartTime: Moment, rangeEndTime: Moment) => { return optionStartTime.isSameOrBefore(rangeEndTime); } : (optionStartTime: Moment, rangeEndTime: Moment) => { return optionStartTime.isBefore(rangeEndTime); };
            timeArray = this.getOptionsWithinRange(startTime, endTime, interval, selectedTime, true, comparisonFn);
        } else if (validRanges || invalidRanges) {
            // assume the durations are sorted chronologically and that no durations overlap
            while (validRanges.length || invalidRanges.length) {
                let nextDuration;
                let rangeIsValid;
                if (!validRanges.length) {
                    nextDuration = invalidRanges.shift();
                    rangeIsValid = false;
                } else if (!invalidRanges.length) {
                    nextDuration = validRanges.shift();
                    rangeIsValid = true;
                } else {
                    const nextValidDuration = validRanges[0];
                    const nextInvalidDuration = invalidRanges[0];
                    if (nextValidDuration.startTime.isBefore(nextInvalidDuration.startTime)) {
                        nextDuration = validRanges.shift();
                        rangeIsValid = true;
                    } else {
                        nextDuration = invalidRanges.shift();
                        rangeIsValid = false;
                    }
                }
                let rangeStart = nextDuration.startTime;
                // If an anchor time is provided, we additionally constrain ranges to not begin before the anchor time, and to begin an interval
                // length after the anchor time
                if (anchorTime && anchorTime.isSameOrAfter(rangeStart)) {
                    rangeStart = anchorTime.clone().add(interval, "minutes");
                }
                const rangeEnd = nextDuration.endTime;
                const comparisonFn = endTimeInclusive && rangeIsValid ? (optionStartTime: Moment, rangeEndTime: Moment) => { return optionStartTime.isSameOrBefore(rangeEndTime); } : (optionStartTime: Moment, rangeEndTime: Moment) => { return optionStartTime.isBefore(rangeEndTime); };
                timeArray = timeArray.concat(this.getOptionsWithinRange(rangeStart, rangeEnd, interval, selectedTime, rangeIsValid, comparisonFn));
            }
        }

        const selectedTimeOffset = DateUtils.getDifferenceInMinutesFromMoments(startTime, selectedTime);
        const addFreeformSelectedDuration: boolean = selectedTimeOffset % (interval || DEFAULT_INTERVAL_MINUTES) !== 0;
        if (addFreeformSelectedDuration) {
            const key = this.getOptionStartTimeKey(selectedTime);
            timeArray.push({
                key: key,
                text: DateTimeFormatter.getDateTimeAsString(selectedTime, DateTimeFormatType.Time),
                time: selectedTime,
                selected: true,
                enabled: true
            });
        }

        timeArray.sort(
            (a: TimePickerOption, b: TimePickerOption) => {
                return a.time.unix() - b.time.unix();
            }
        );

        return timeArray;
    }

    /**
     * Attempt to parse the user input into a valid Moment. Return the attempt.
     * @param value
     */
    private parseFreeformInput(value: string): Moment {
        let time = null;
        value = this.props.startTime.format(PERMITTED_DATE_FORMAT) + " " + value;
        time = moment(value, PERMITTED_TIME_FORMATS, window.sa.currentUICulture, true /* strict */);
        time = time.isValid() ? time : null;
        if (time) {
            // As users input only hours and minutes, we select the implied date. Because of overnight shifts, we may
            // have to compare the entered hours and to those of the anchorTime to determine which date should be used.
            if (this.props.anchorTime) {
                // If the parsed time is before the anchor time, we know we have "overshot" and increment the date
                if (time.isBefore(this.props.anchorTime)) {
                    time.add(1, "day");
                }
            }
        }

        return time;
    }

    /**
     * When the time is changed due to selection of an option or user input via the keyboard, we will
     * attempt to retrieve the time from the selected option or from the user input. If this is successful,
     * we will call this.props.onChanged to propagate the time, If not, we will call this.props.onInvalid to inform
     * the containing component of invalid input.
     * @param option
     * @param index
     * @param value
     */
    private onTimeChanged = (event: React.FormEvent<IComboBox>, option?: TimePickerOption, index?: number, value?: string) => {
        if (this.props.onChanged) {
            let time = null;
            if (option && option.time && option.enabled) {
                time = option.time;
            } else if (value) {
                time = this.parseFreeformInput(value);
            }
            if (time) {
                this.props.onChanged(time.clone());
            } else {
                if (this.props.onInvalid) {
                    this.props.onInvalid();
                }
            }
        }
    }

    /**
     * Helper for calculating the key for specified time item in the dropdown
     */
    public static calculateTimePickerEntryKey(time: Moment): string {
        let key: string = "";
        if (time) {
            key = time.valueOf().toString();
        }
        return key;
    }

    componentDidMount() {
        if (this._timePicker && this._timePicker.current && this.props.autofocus) {
                this._timePicker.current.focus();
        }
    }

    render() {
        const { label, selectedTime, allowFreeform, autoComplete, ariaLabel, buttonIconProps, styles: comboboxStyles } = this.props;
        const classes = classNames(this.props.className, styles.timePickerComboBox, { [`${styles.invalid}`]: (this.props.inputValid !== undefined && !this.props.inputValid) });
        return (
            <ComboBox
                disabled={ this.props.disabled }
                componentRef={ this._timePicker }
                buttonIconProps={ buttonIconProps || { iconName: "ChevronDown" } }
                className={ classes }
                selectedKey={ TimePicker.calculateTimePickerEntryKey(selectedTime) }
                allowFreeform={ allowFreeform }
                autoComplete={ autoComplete }
                label={ label }
                ariaLabel={ ariaLabel }
                options={ this.getTimeOptions() }
                onChange={ this.onTimeChanged }
                onRenderOption={ this.onRenderOption }
                data-automation-id= { this.props.dataAutomationId }
                styles={ { ...comboboxStyles, callout: styles.timePickerCallout, optionsContainer: styles.timePickerOptionsContainer} }
                />
        );
    }
}