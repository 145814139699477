import {
    IMemberEntity,
    IShiftEntity,
    ITagEntity,
    MemberEntity,
    MemberStates,
    UserPolicySettingsEntity
} from "sh-models";
import StringsStore from "sh-strings/store";
import TagUtils from "sh-application/utility/TagUtils";
import UserUtils from "./UserUtils";
import { DataProcessingHelpers } from "sh-services";
import { getInitials, getRTL, PersonaInitialsColor } from "@fluentui/react";
import { MobxUtils } from "sh-application";
import { OROPERATORTYPE } from "../../StaffHubConstants";
import { TagStore } from "sh-tag-store";
import { TeamStore } from "sh-team-store";
import { UserStore } from "sh-stores";
import StringUtils from "./StringUtils";
import indexComparator from "./indexComparator";

/**
 * Utilities for Members
 */
export default class MemberUtils {

    /**
     * Returns an array of active members (IMemberEntity) in a group
     */
    public static getActiveMembersInGroup(groupTagId: string): IMemberEntity[] {
        let activeMembers: IMemberEntity[] = [];
        let memberIdsInGroup = new Map<string, boolean>();

        const tag = TagStore().tags.get(groupTagId);
        // go through active tag and build map of unique membersIds
        if (tag && TagUtils.isActiveTag(tag)) {
            tag.memberIds.forEach((memberId: string) => {
                memberIdsInGroup.set(memberId, true);
            });
        }

        // for each memberId, get the member entity and if they are active, add them
        // to the activeMembers array
        memberIdsInGroup.forEach((value: boolean, key: string) => {
            const member = TeamStore().members.get(key);
            if (MemberUtils.isMemberActive(member)) {
                activeMembers.push(member);
            }
        });

        return activeMembers;
    }

    /**
     * Returns an array of active members (IMemberEntity) on the schedule
     */
    public static getActiveMembersOnSchedule(): IMemberEntity[] {
        let activeMembers: IMemberEntity[] = [];

        TeamStore().members.forEach((member: IMemberEntity) => {
            if (MemberUtils.isMemberActive(member)) {
                activeMembers.push(member);
            }
        });

        return activeMembers;
    }

    /**
     * Gets all the members (regardless of state) from the member store.
     */
    public static getAllMembersArray(): IMemberEntity[] {
        return DataProcessingHelpers.getArrayFromMap(TeamStore().members);
    }

    /**
     * Returns an array of memberIds for all members (regardless of state). Optionally sorts by index.
     * @param {boolean} sortByIndex - if true, returned memberIds are sorted by the index in their original memberEntity
     */
    public static getAllMemberIds(sortByIndex?: boolean): string[] {
        let members = MemberUtils.getAllMembersArray();
        if (sortByIndex) {
            // Sort members by their index
            members = members.sort(indexComparator);
        }
        // map the members into ids
        return members.map((member) => { return member && member.id; });
    }

    /**
     * Gets the display name from member id
     * @param memberId
     */
    public static getDisplayNameFromMemberId(memberId: string) {
        const member = TeamStore().members.get(memberId);
        return this.getDisplayNameForMember(member);
    }

    /**
     * Calculate the display name
     */
    public static getDisplayName(firstName: string, lastName: string) {
        let displayName;
        if (lastName) {
            const fullNameFormat: string = StringsStore().registeredStringModules.get("common").strings.get("fullNameFormat");
            displayName = fullNameFormat.format(firstName, lastName);
        } else {
            displayName = firstName;
        }

        // remove extra whitespace
        if (displayName) {
            displayName = displayName.trim();
        }

        return displayName;
    }

    /**
     * Returns if the member has had their data redacted due to a GDPR redaction request
     * @param member The member
     */
    public static hasMemberDataBeenRedacted(member: IMemberEntity) {
        // A redacted member is deleted with no display name
        return !member ||
            ((member.state == MemberStates.Deleted || member.state == MemberStates.AutoDeleted) &&
            (!member.displayName || !member.displayName.trim()));   // Service sometimes sends a  " " for a redacted user display name
    }

    /**
     * Calculate the display name for a member
     */
    public static getDisplayNameForMember(member: IMemberEntity, truncateLastName: boolean = false) {
        let displayName = "";
        if (member) {
            if (truncateLastName) {
                displayName = MemberUtils.getTruncatedName(member.firstName, member.lastName);
            } else {
                displayName = member.displayName;
                if (!displayName) {
                    displayName = MemberUtils.getDisplayName(member.firstName, member.lastName);
                }
            }

            // remove extra whitespace
            if (displayName) {
                displayName = displayName.trim();
            }

            // Set the display name to be "Deleted User" for a user whose data has been redacted
            if (MemberUtils.hasMemberDataBeenRedacted(member)) {
                displayName = StringsStore().registeredStringModules.get("common").strings.get("deletedUserDisplayName");
            }
        }
        return displayName;
    }

    /**
     * Display first name for a user, for example in ShiftRequest list
     * @param member member entity
     * @returns string the first name of the member enitity,
     * if the first name is not available, return default string "Employee" for employee,
     * and "Manager" for manager
     */
    public static getDisplayFirstName(member: IMemberEntity): string {
        if (!member) {
            return "";
        }

        if (member.firstName) {
            return member.firstName;
        } else {
            if (MemberUtils.isAdmin(member)) {
                return StringsStore().registeredStringModules.get("common").strings.get("managerDefaultName");
            } else {
                return StringsStore().registeredStringModules.get("common").strings.get("employeeDefaultName");
            }
        }

    }

    /**
     * Return a custom color for a persona (if required)
     * Otherwise it returns undefined, which allows the persona control to use the default logic to determine a color
     */
    static getCustomPersonaInitialColor(member: IMemberEntity): PersonaInitialsColor {
        // Return black for a redacted member
        return MemberUtils.hasMemberDataBeenRedacted(member) ? PersonaInitialsColor.black : undefined;
    }

    /**
     * Truncates last name to just the initial and returns this initial concatenated with first name
     */
    public static getTruncatedName(firstName: string, lastName: string): string {
        let lastNameInitial = "";
        const fullNameFormat: string = StringsStore().registeredStringModules.get("common").strings.get("lastNameInitialFormat");
        if (lastName && lastName.length > 0) {
            lastNameInitial = lastName.charAt(0);
        }
        return fullNameFormat.format(firstName, lastNameInitial);
    }

    /**
     * Checks to see if the member object matches the filtering criteria
     * Filtering criteria include first name, last name and email address and all three are optional
     * @param member - member object that needs to be matched against name and email
     * @param firstNameStartsWith - first name starts with filter
     * @param lastNameStartsWith - last name starts with filter
     * @param primaryEmailStartsWtih - email starts with filter
     * @param operatorType - operator used for matching - "OR" returns true if the member object matches any of the filtering criteria, "AND" returns true only if the member matches all of supplied filtering criteria
     * @param searchString - search string to match against display name
     * @returns boolean - true, if the member object matches the name or email parameters
     */
    // TODO: Refactor this function to pass IUserQuery instead of individual parameters.
    public static filterMember(member: IMemberEntity, firstNameStartsWith?: string, lastNameStartsWith?: string, primaryEmailStartsWith?: string, operatorType: string = OROPERATORTYPE, searchString?: string): boolean {
        if (!member) {
            return false;
        }

        const firstNameMatch = member.firstName && firstNameStartsWith && member.firstName.toLowerCase().startsWith(firstNameStartsWith.toLowerCase());
        const lastNameMatch = member.lastName && lastNameStartsWith && member.lastName.toLowerCase().startsWith(lastNameStartsWith.toLowerCase());
        const displayNameMatch = member.displayName && searchString && member.displayName.toLowerCase().includes(searchString.toLowerCase());

        let email = null;
        if ( member && (member instanceof MemberEntity || member.email )) {
            email = (member as MemberEntity).email;
        }

        const emailMatch = email && primaryEmailStartsWith && email.toLowerCase().startsWith(primaryEmailStartsWith.toLowerCase());
        let result;
        if (operatorType === OROPERATORTYPE) {
            result = firstNameMatch || lastNameMatch || emailMatch || displayNameMatch;
        } else {
            result = firstNameStartsWith || lastNameStartsWith || primaryEmailStartsWith || displayNameMatch;
            // "AND" the result
            if (firstNameStartsWith) {
                result = firstNameMatch;
            }

            if (lastNameStartsWith) {
                result = result && lastNameMatch;
            }

            if (primaryEmailStartsWith) {
                result = result && emailMatch;
            }

            if (searchString) {
                result = result && displayNameMatch;
            }
        }

        return !!result;
    }

    /**
     * Gets whether given member has been deleted from a team.
     * Returns true for external team members or deleted team members, returns false otherwise.
     * Important: external team members assigned to home team cross-location shifts are set as Deleted or AutoDeleted team member from Service.
     * @param member The member.
     * @returns Whether given member has been deleted from a team.
     */
    public static isMemberDeletedFromTeam(member?: IMemberEntity): boolean {
        return member?.state == MemberStates.Deleted || member?.state == MemberStates.AutoDeleted;
    }

    /**
     * Function to get cross location team id for the given member.
     * For the remote member, member state is AutoDeleted or Deleted and has a cross location shift true.
     * @param member : Member Entity.
     * @param memberShifts: Member Shifts.
     * @returns The cross-location TeamId for the given member.
     */
    public static getCrossLocationTeamId(member: IMemberEntity, memberShifts: IShiftEntity[]): string | undefined {
        // Check if the member state is AutoDeleted or Deleted and has crossed location shifts
        if (MemberUtils.isMemberDeletedFromTeam(member)) {
            const crossLocationShift = memberShifts.find(shift => shift.isCrossLocationShift && shift.senderTeamId);
            const crossLocationTeamId = crossLocationShift?.senderTeamId;
            return crossLocationTeamId;
        }
        return undefined;
    }

    /**
     * Function to get the member with userId
     * @param userId : User ID of the member
     * @param includeNonDeletedMembers: Include/Exclude members with state as AutoDeleted
     */
    public static getMemberWithUserId(userId: string, includeNonDeletedMembers?: boolean) {
        let userMember: IMemberEntity = MobxUtils.MapToReadonlyArray(TeamStore().members).find((member: IMemberEntity) => {
            return member.userId && member.userId === userId && (includeNonDeletedMembers ? true : !this.isMemberDeletedFromTeam(member));
        });

        return userMember;
    }

    /**
     * Get the number of members in a list deleted from a list of members
     * @param members
     */
    public static getNumMembersDeletedFromTeam(members: IMemberEntity[]): number {
        let total: number = 0;
        for (let i = 0; i < members.length; i++) {
            if (MemberUtils.isMemberDeletedFromTeam(members[i])) {
                total++;
            }
        }
        return total;
    }

    public static isShiftMemberActive(shift: IShiftEntity): boolean {
        return TeamStore().members && shift.memberId && this.isMemberActive(TeamStore().members.get(shift.memberId));
    }

    /**
     * Function that checks to see if the member is in an active state
     * @param member
     */
    public static isMemberActive(member: IMemberEntity): boolean {
        return !!(member && member.state === MemberStates.Active);
    }

    /**
     * Function that checks to see if the member is missing contact info
     * @param member
     */
    public static isMemberMissingContactInfo(member: IMemberEntity): boolean {
        return member && !member.email && !member.phoneNumber;
    }

    /**
     * Get the number of members in a list that are missing contact info
     * @param members
     */
    public static getNumMembersMissingContactInfo(members: IMemberEntity[]): number {
        let total: number = 0;
        for (let i = 0; i < members.length; i++) {
            if (MemberUtils.isMemberMissingContactInfo(members[i])) {
                total++;
            }
        }
        return total;
    }

    /**
     * Comparator for sorting members by their index
     */
    public static memberComparator(firstMember: IMemberEntity, secondMember: IMemberEntity): number {
        if (typeof(firstMember.index) === "undefined") {
            return 1;
        } else if (typeof(secondMember.index) === "undefined") {
            return -1;
        } else {
            return firstMember.index < secondMember.index ? -1 : (firstMember.index > secondMember.index ? 1 : 0);
        }

    }

    /**
     * Comparator for sorting members by their display name
     */
    public static memberNameComparator(firstMember: IMemberEntity, secondMember: IMemberEntity): number {
        return StringUtils.genericStringComparator(firstMember.displayName, secondMember.displayName);
    }

    /**
     * Retuns the member's inititals, optimized for their current language culture.
     * @returns {string} Users initials
     */
    public static getInitials(member: IMemberEntity): string {
        return getInitials(MemberUtils.getDisplayNameForMember(member), getRTL());
    }

    /**
     * Returns true if the current user is an admin or a schedule owner
     */
    public static isAdmin(member: IMemberEntity): boolean {
        const userPolicy: UserPolicySettingsEntity = UserStore() && UserStore().userPolicySettings;
        const isScheduleOwner: boolean = UserUtils.isScheduleOwnerPermissionsEnabled(userPolicy) ? true : false;
        return (member && member.isAdmin) || isScheduleOwner;
    }

    /**
     * Returns the member id of the current user
     */
    public static getCurrentUserMemberId(): string {
        return TeamStore().me && TeamStore().me.id;
    }

    /**
     * Do two members have any tags in common
     * @param tags
     * @param memberOne
     * @param memberTwo
     */
    public static doMembersHaveAnyMatchingTags(tags: ITagEntity[], memberOne: IMemberEntity, memberTwo: IMemberEntity): boolean {
        let hasMatchingTag: boolean = false;
        const memberOneTags: ITagEntity[] = TagUtils.getTagsForMember(tags, memberOne, null);
        const memberTwoTags: ITagEntity[] = TagUtils.getTagsForMember(tags, memberTwo, null);

        if (memberOneTags && memberTwoTags) {
            hasMatchingTag = memberOneTags.some((memberOneTag: ITagEntity) => {
                return memberTwoTags.some((memberTwoTag: ITagEntity) => {
                    return memberOneTag.id === memberTwoTag.id;
                });
            });
        }
        return hasMatchingTag;
    }

    /**
     * Returns an array of memberIds for all filtered members
    */
    public static getFilteredMemberIds(filteredMembers: any): string [] {
        let members = DataProcessingHelpers.getArrayFromMap(filteredMembers);
        // map the members into ids
        return members.map((member: IMemberEntity) => { return member && member.id; });
    }

    /**
    * Returns an array of memberNames for all filtered members
    */
    public static getFilteredMemberNames(filteredMembers: any): string [] {
        let members = DataProcessingHelpers.getArrayFromMap(filteredMembers);
        // map the members into names
        return members.map((member: IMemberEntity) => { return member && this.getDisplayNameForMember(member, false /*truncateLastName */); });
    }
}