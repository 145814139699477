/**
 * Instrumentation Events
 */
export const instrumentationEvents = {
    UserBI: "userbi",
    LoginEvent: "LoginEvent",
    LoginError: "LoginError",
    SignIn: "SignIn",
    SelectTeam: "SelectTeam",
    CreateNewTeam: "CreateNewTeam",
    CreateTeam: "CreateTeam",
    ShiftEventEditPopupLaunch: "ShiftEventEditPopupLaunch",
    ShiftEventEditPopupSave: "ShiftEventEditPopupSave",
    ShiftEventEditPopupCancel: "ShiftEventEditPopupCancel",
    ShiftEventEditPopupDelete: "ShiftEventEditPopupDelete",
    GroupByTags: "GroupByTags",
    GroupByShifts: "GroupByShifts",
    ExportToPDF: "ExportToPDF",
    RestartApp: "RestartApp",
    PageLoadTime: "PageLoadTime",
    PublishPressed: "PublishPressed",
    UnpublishPressed: "UnpublishPressed",
    PublishCompleted: "PublishCompleted",
    UnpublishCompleted: "UnpublishCompleted",
    SaveChangesMember: "SaveChangesMember", // Team Page: Save changes for member initiated
    SaveChangesTag: "SaveChangesTag",
    CancelChangesTag: "CancelChangesTag",
    Logout: "Logout",
    TagNameUpdated: "TagNameUpdated",
    ScheduleViewDeleteTagPressed: "ScheduleViewDeleteTagPressed",
    AddNewPersonToSchedule: "AddNewPersonToSchedule",
    AddExistingPersonToGroup: "AddExistingPersonToGroup",
    AddMemberToSchedule: "AddMemberToSchedule",
    ScheduleGroupSearchMembersFailed: "ScheduleGroup_AddMembersSearchFailed",
    ScheduleViewPageSkippedAddContactInfo: "ScheduleViewPageSkippedAddContactInfo",
    ContextMenuOpened: "ContextMenuOpened",
    ContextMenuItemSelected: "ContextMenuItemSelected",
    ScheduleViewCellRightClick: "ScheduleViewCellRightClick", // Schedule View: Cell was right clicked
    SignalRConnectionEvent: "SignalRConnectionEvent",
    ToggleShiftEditMode: "ToggleShiftEditMode",
    DayNoteCreated: "DayNote_Created",
    DayNoteEdited: "DayNote_Edited",
    DayNoteDeleted: "DayNote_Deleted",
    NavigateToView: "NavigateToView",
    Navigation: "Navigation",
    ShiftLookupOpened: "ShiftLookup_Opened",
    ShiftLookupAction: "ShiftLookup_Actions",
    ExportDataEntrypointPressed: "ExportDataEntrypointPressed",
    ExportDataSubmitted: "ExportDataSubmitted",
    ExportDataWithOptions: "ExportDataWithOptions",
    ExportToMatchImport: "ExportToMatchImport",
    PatternLengthChangedFRE: "PatternLengthChangedFRE",
    ChangePatternTypeFRE: "ChangePatternTypeFRE",
    ChangePatternStartDateFRE: "ChangePatternStartDateFRE",
    CompletePublishFRE: "CompletePublishFRE",
    CompletePublish: "CompletePublish",
    CancelPublish: "CancelPublish",
    Unpublish: "Unpublish",
    PublishLinkToTeamSettingsPage: "PublishLinkToTeamSettingsPage",
    TeamSettingsUpdated: "TeamSettingsUpdated",
    ResetTeamSettings: "ResetTeamSettings",
    RemoveMemberFromTag: "RemoveMemberFromTag",
    RemoveMemberFromTagConfirmationShown: "RemoveMemberFromTagConfirmationShown",
    RemoveMemberFromTagBlocked: "RemoveMemberFromTagBlocked",
    TimeOffReasonsUpdated: "TimeOff_ReasonsUpdated",
    TourBegin: "TourBegin",
    TourSkipped: "TourSkipped",
    TourFinished: "TourFinished",
    TourStopCompleted: "TourStopCompleted",
    FREWelcomeShown: "FRE_WelcomeShown",
    FREAddPeopleShown: "FRE_AddPeopleShown",
    FREAddShiftShown: "FRE_AddShiftShown",
    FREShareSaveShown: "FRE_ShareSaveShown",
    FRENewTeamShareShown: "FRE_NewTeamShareShown",
    FREExistingTeamShareShown: "FRE_ExistingTeamShareShown",
    FREShowTourAgainClick: "FRE_ShowTourAgainClick",
    FREAvailabilityShown: "FRE_AvailabilityShown",
    FREOpenShiftsShown: "FRE_OpenShiftsShown",
    BeginSetup: "BeginSetup",
    FinishSetup: "FinishSetup",
    DefaultTimeZoneUsed: "DefaultTimeZoneUsed",
    StaffHubToggled: "TenantSettings_EnableStaffHubClicked",
    EnforceLicenseCheck: "TenantSettings_EnforceLicenseCheckClicked",
    SelfProvisioningToggled: "TenantSettings_SelfProvisioningClicked",
    TeamUsageReportingDownloadButtonClicked:
        "TenantSettings_TeamUsageReportingDownloadButtonClicked",
    CorporateAnnouncements_ManageAnnouncementsLinkClicked:
        "CorporateAnnouncements_ManageAnnouncementsLinkClicked",
    DomainUpdated: "DomainChanged",
    ExtensibilityLinksSaved: "EmployeeResources_SaveClicked",
    PowerAppsInfoLinkClicked: "EmployeeResources_PowerAppsInfoLinkClicked",
    ExtensibilityTestLinkClicked: "EmployeeResources_TestLinkClicked",
    GroupPrefixEntered: "TenantSettings_O365GroupPrefixEntered",
    GroupProvisioned: "GroupProvisioned",
    GroupDeleteOnScheduleClicked: "Groups_DeleteOnScheduleClicked",
    GroupCommitDeleteOnScheduleClicked: "Groups_CommitDeleteOnScheduleClicked",
    DriveProvisioned: "DriveProvisioned",
    DirectoryQuotaLimitExceeded: "DirectoryQuotaLimitExceeded",
    ChangeTimeZoneDialogShown: "ChangeTimeZoneDialogShown",
    HelpandSupportClicked: "HelpandSupportClicked",
    SuggestIdeaClicked: "SuggestIdeaClicked",
    CellSelected: "CellSelected",
    TypeToOpenShiftLookup: "TypeToOpenShiftLookup",
    ScreenEvent: "ScreenEvent",
    DataBootstrapped: "DataBootstrapped",
    AppStartup: "AppStartup",
    SchedulePageRenderCompleteTime: "SchedulePageRenderCompleteTime",
    ProgressiveRenderComplete: "ProgressiveRenderComplete",
    ContentDemoFileClicked: "Content_DemoFileClicked",
    ContentFileClicked: "Content_FileClicked",
    ContentDemoFileDeleted: "Content_DemoFileDeleted",
    ContentFileDeleted: "Content_FileDeleted",
    ContentLinkUploaded: "Content_LinkUploaded",
    ContentUpload: "Content_Upload",
    FilesClassificationClicked: "FilesClassificationClicked",
    MemberContextMenuOpened: "Member_ContextMenuOpened",
    MemberInitiateAddClicked: "Member_InitiateAddClicked",
    MemberCommitAddClicked: "Member_CommitAddClicked",
    MemberUpdateClicked: "Member_UpdateClicked",
    MemberCommitUpdateClicked: "Member_CommitUpdateClicked",
    MemberCommitDeleteClicked: "Member_CommitDeleteClicked",
    MemberDeleteClicked: "Member_DeleteClicked",
    MemberErrorDuringCommitClicked: "Member_ErrorDuringCommitClicked",
    MemberCancelChangeClicked: "Member_CancelChangeClicked",
    ReorderGroupedMemberClicked: "Reorder_GroupedMemberClicked",
    ReorderActionCommit: "Reorder_ActionCommit",
    Reorder_MemberErrors: "Reorder_MemberErrors",
    Activities_LookupMenuOpened: "Activities_LookupMenuOpened",
    Activities_LookupMenuItemClicked: "Activities_LookupMenuItemClicked",
    ShiftAndActivitiesChangeInTabs: "ShiftAndActivitiesChangeInTabs",
    Activities_DialogBoxAddActivityClicked: "Activities_DialogBoxAddActivityClicked",
    Activities_CreatedOrModified: "Activities_CreatedOrModified",
    Activities_ActivityDeleted: "Activities_ActivityDeleted",
    ActivityErrors: "ActivityErrors",
    Activities_ActivityUndoClicked: "Activities_ActivityUndoClicked",
    ActivityCopied: "ActivityCopied",
    ActivityPasted: "ActivityPasted",
    CopyShiftActivitiesWithShifts: "CopyShiftActivitiesWithShifts",
    UnknownError: "UnknownError",
    NetworkError: "NetworkError",
    /**
     * This is related to Content-Security Policy (CSP) violation.
     * For example, if we're using a domain which is not allowed by CSP, we'll log this error.
     */
    SecurityPolicyViolationError: "SecurityPolicyViolationError",
    NativeTimeClock_SetUpClicked: "NativeTimeClock_SetUpClicked",
    NativeTimeClockSettings_ChangeClicked: "NativeTimeClockSettings_ChangeClicked",
    NativeTimeClockFRE_NextClicked: "NativeTimeClockFRE_NextClicked",
    NativeTimeClockFRE_Cancel: "NativeTimeClockFRE_Cancel",
    NativeTimeClock_LocationEnabled: "NativeTimeClock_LocationEnabled",
    NativeTimeClock_GPSLearnMoreClicked: "NativeTimeClock_GPSLearnMoreClicked",
    NativeTimeClock_AddressEntered: "NativeTimeClock_AddressEntered",
    NativeTimeClockEnableClicked: "NativeTimeClockEnableClicked",
    NativeTimeClockSettings_ChangeCommitted: "NativeTimeClockSettings_ChangeCommitted",
    NativeTimeClock_ExportClicked: "NativeTimeClock_ExportClicked",
    NativeTimeClock_Errors: "NativeTimeClock_Errors",
    NativeTimeClock_BingEnableErrors: "NativeTimeClock_BingEnableErrors",
    ShiftAndActivities_DialogBoxTriggered: "ShiftAndActivities_DialogBoxTriggered",
    ShiftAndActivities_DialogBoxSaveClicked: "ShiftAndActivities_DialogBoxSaveClicked",
    ShiftAndActivities_DialogBoxCancelClicked: "ShiftAndActivities_DialogBoxCancelClicked",
    ShiftAndActivities_DeleteShift: "ShiftAndActivities_DeleteShift",
    OpenShiftEditMessagingShown: "OpenShiftEdit_MessagingShown",
    EmployeeShiftDetails: "Employee_ShiftDetails",
    TimeOffDeleteShift: "TimeOff_DeleteShift",
    ImportDataFileLoadedAndImportStarted: "ImportDataFileLoadedAndImportStarted",
    ImportDataImportCompleteSuccess: "ImportDataImportCompleteSuccess",
    ImportDataImportCompleteFailed: "ImportDataImportCompleteFailed",
    ImportDataEntrypointPressed: "ImportDataEntrypointPressed",
    CustomFieldUpdated: "CustomFieldUpdated",
    TenantSettingsCustomFieldsModified: "TenantSettings_CustomFieldsModified",
    Shift_Paste: "Shift_Paste",
    Shift_Delete: "Shift_Delete",
    Shift_Copy: "Shift_Copy",
    Shift_Save: "Shift_Save",
    Publish_ShareShift: "Publish_ShareShift",
    Undo_Action: "Undo_Action",
    O365Shell: "O365Shell",
    CopyDateRange_Clicked: "CopyDateRange_Clicked",
    CopyDateRange_Completed: "CopyDateRange_Completed",
    CopyDateRange_Failed: "CopyDateRange_Failed",
    CopyDatePicker_Clicked: "CopyDatePicker_Clicked",
    CopyDatePicker_Completed: "CopyDatePicker_Completed",
    CopyDatePicker_Failed: "CopyDatePicker_Failed",
    CopyDatePicker_RetryClicked: "CopyDatePicker_RetryClicked",
    CopyDatePicker_CancelClicked: "CopyDatePicker_CancelClicked",
    ActivityAndBreaksPanel: "ActivitAndBreaksPanel",
    DragAndDropCompleted: "DragAndDrop_Completed",
    ScheduleNavigation: "Schedule_Navigation",
    Redo_Action: "Redo_Action",
    Print: "Print",
    Onboarding: "Onboarding",
    ExternalTeam: "ExternalTeam",
    PublishShareWithTeamComplete: "Publish_ShareWithTeamComplete",
    PublishViewSharedPanel: "Publish_ViewSharedPanel",
    PublishShareWithTeamClicked: "Publish_ShareWithTeamClicked",
    RecallMenuOptionSelected: "RecallMenuOptionSelected",
    RecallClicked: "RecallClicked",
    ShareWithTeamClicked: "ShareWithTeamClicked",
    RecallComplete: "RecallComplete",
    ScheduleToggle: "ScheduleToggle",
    ScheduleNavigationToView: "Schedule_NavigationToView",
    ConvergedOnboarding: "Converged_Onboarding",
    ScheduleTeamPicker: "ScheduleTeamPicker",
    ProvisionScheduleTeam: "ProvisionScheduleTeam",
    ScheduleHeader: "ScheduleHeader",
    TenantSettings: "TenantSettings",
    ErrorWatcherActionClicked: "ErrorWatcher_ActionClicked",
    AssignPeople: "AssignPeople",
    Requests: "Requests",
    ImportExcel: "ImportExcel",
    PageRender: "PageRender",
    PerformanceScheduleCreated: "PerformanceScheduleCreated",
    PerformanceScheduleCleaned: "PerformanceScheduleCleaned",
    Exceptions: "Exceptions",
    ManageTeamRedirect: "ManageTeamRedirect",
    AppPreInitFailed: "AppPreInitFailed",
    GetDataInDateRange: "GetDataInDateRange",
    GetDataInDateRangePage: "GetDataInDateRangePage",
    Scrolling: "Scrolling",
    ECSInitFailed: "ECSInitFailed",
    ECSConfigUpdated: "ECSConfigUpdated",
    RequestFlyoutOpened: "RequestFlyoutOpened",
    RequestFlyoutSwapClicked: "RequestFlyoutSwapClicked",
    RequestFlyoutOfferClicked: "RequestFlyoutOfferClicked",
    ThirdPartyNoticeClicked: "ThirdPartyNoticeClicked",
    TeamShiftsViewClicked: "TeamShiftsViewClicked",
    YourShiftsViewClicked: "YourShiftsViewClicked",
    FREEmployeeViewMenuShown: "FREEmployeeViewMenuShown",
    FREScheduleFilterButtonShown: "FREScheduleFilterButtonShown",
    FilterButtonClicked: "FilterButtonClicked",
    FilterByGroupExpandCollapseToggle: "FilterByGroupExpandCollapseToggle",
    FilterByMembersExpandCollapseToggle: "FilterByMembersExpandCollapseToggle",
    FilterByShiftsExpandCollapseToggle: "FilterByShiftsExpandCollapseToggle",
    FilterClearClicked: "FilterClearClicked",
    FilterSelectAllClicked: "FilterSelectAllClicked",
    FilterApplied: "FilterApplied",
    CopyScheduleFilterApplied: "CopyScheduleFilterApplied",
    HelpPageRedirect: "HelpPageRedirect",
    LargeTeamsErrors: "LargeTeamsErrors",
    WFIError: "WFIError",
    SwapFilteringEnabled: "SwapFilteringEnabled",
    RequestGetMonthShifts: "RequestGetMonthShifts",
    FullDayToggleClicked: "FullDayToggleClicked",
    FRETimeClockButtonShown: "FRETimeClockButtonShown",
    TimeClockWeb: "TimeClockWeb",
    CommandBarAddTagPressed: "CommandBarAddTagPressed",
    RenameGroupClicked: "RenameGroupClicked",
    RenameDialogOkClicked: "RenameDialogOkClicked",
    RenameDialogCancelClicked: "RenameDialogCancelClicked",
    ConflictBadgeIconClicked: "ConflictBadgeIconClicked",
    ConflictsViewSettingClicked: "ConflictsViewSettingClicked",
    ConflictsPopupMoreInfoClicked: "ConflictsPopupMoreInfoClicked",
    ConflictsPopupShiftObjectClicked: "ConflictsPopupShiftObjectClicked",
    ConflictsPopupIgnoreClicked: "ConflictsPopupIgnoreClicked",
    ConflictsCalculated: "ConflictsCalculated",
    GridCalcError: "GridCalcError",
    RequestWFMWeb: "RequestWFMWeb",
    GraphApiRequestCompleted: "GraphApiRequestCompleted",
    GraphApiRequestFailed: "GraphApiRequestCompleted",
    GraphMembersMerged: "GraphMembersMerged",
    TeamMemberPrivacySettingsUpdate: "TeamMemberPrivacySettingsUpdate",
    // TODO (Jeremyroe): Remove and update phase 1
    TeamMemberPrivacySettingsUpdateFailed: "TeamMemberPrivacySettingsUpdateFailed",
    // Open shifts experience
    OpenShiftsExperience: "OpenShiftsExperience",
    OpenShiftsExperienceTeamsLoaded: "OpenShiftsExperience_TeamsLoaded",
    OpenShiftsExperienceOpenShiftsLoaded: "OpenShiftsExperience_OpenShiftsLoaded",
    OpenShiftsExperienceOpenShiftRequest: "OpenShiftsExperience_OpenShiftRequest",
    // UserPersona component
    UserPersona: "UserPersona",
    // Team settings experience
    TeamSettingsExperience: "TeamSettingsExperience"
};
